<template>
  <div class="login flex" v-if="loading">
    <div class="login-bg"></div>
    <div class="login-left">
      <div class="login-title">登录</div>
      <el-form ref="elForm" :model="form" status-icon :rules="rules" label-width="120px" label-position="top"
        @keyup.enter="submit">
        <el-form-item prop="username">
          <el-input v-model="form.username" type="text" placeholder="请输入账号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as API_login from "@/api/login";


export default {
  name: '',
  components: {
  },
  data() {
    return {
      loading: false,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入帐号',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
          },
        ],
      }
    }
  },
  created() {
    if (this.getStore('token')) {
      this.$router.replace('/')
      return false
    }
    this.loading = true
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          API_login.postLogin(this.form).then(res => {
            console.log(res)
            this.setStore('user', res.user)
            this.setStore('token', res.token)
            this.$router.replace('/')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.login {
  position: absolute;
  left: 0;
  right: 0;
  top: calc((100vh - 560px) / 2);
  width: 800px;
  height: 560px;
  padding: 10px;
  margin: auto;
  box-shadow: 0px 6px 14px 0px #ced2d6;
  background-color: #fff;
  .login-bg {
    width: 403px;
    height: 540px;
    height: 100%;
    background: url(~@/static/images/login-bg.png) center center no-repeat;
    background-size: 100% 100%;
  }
  .login-left {
    width: 370px;
    padding: 65px;
    box-sizing: border-box;
    height: 100%;
    background-color: #fff;
    .login-title {
      color: $primary;
      font-size: 34px;
      font-weight: bolder;
      margin-bottom: 30px;
    }
    :deep(.el-form-item__label) {
      height: 40px;
      color: $primary;
    }
    :deep(.el-form-item) {
      margin-bottom: 30px;
    }
    :deep(input) {
      background-color: #f1f8fe;
      border-color: #f1f8fe;
    }
    .el-button {
      width: 100%;
      margin-top: 20px;
      border-radius: 0;
    }
  }
}
</style>