import router from './router'
import { getStore } from '@/utils/storage'

router.beforeEach((to, from, next) => {
  // console.log(111, to, from, next)
  if (getStore('token') || to.path.includes('/404')) {
    if (to.path.includes('login')) {
      next('/')
    } else {
      next()
    }
  } else if (!to.path.includes('login')) {
    next('/login')
  } else {
    next()
  }
})
