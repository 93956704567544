<template>
  <div class="qr">
    <div class="qr-box">
      <i class="el-icon-circle-close" @click="$router.back()"></i>
      <div class="sale">{{$route.query.sale}}</div>
      <div class="title">{{$route.query.title}}</div>
      <div class="qr-img" id="qrcode" ref="qrcode"></div>
      <div class="desc">微信扫码领取停车优惠券</div>
      <el-button type="primary" @click="handleRefresh">手动刷新</el-button>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2"
import { clearInterval, setInterval } from 'timers';
export default {
  name: 'Qr',
  components: {
  },
  data() {
    return {
      loading: false,
      http: '',
      calculator: '',
    }
  },
  created() {
    this.http = process.env.VUE_APP_HTTP
  },
  mounted() {
    this.getQr()
    this.calculator = setInterval(this.getQr, this.$route.query.time * 60 * 1000)

  },
  beforeUnmount() {
    clearInterval(this.calculator)
  },
  methods: {
    handleRefresh() {
      clearInterval(this.calculator)
      this.getQr()
      this.calculator = setInterval(this.getQr, this.$route.query.time * 60 * 1000)
    },
    getQr() {
      this.$refs.qrcode.innerHTML = ''; //清除二维码方法一
      let text = this.http + '/#/car/coupon/coupon?time=' + (Date.parse(new Date()) / 1000 + this.$route.query.time * 60) + '&id=' + this.$route.query.id
      new QRCode(this.$refs.qrcode, {
        text: text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
        width: 245,
        height: 245,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
      // qrcode.clear();
    },

  }
}
</script>
<style lang="scss" scoped>
.qr {
  min-height: 100vh;
  background-color: $primary;
  color: #fff;
  text-align: center;
}
.qr-box {
  width: 300px;
  padding: 15vh 0 0;
  margin: auto;
}
.el-icon-circle-close {
  position: absolute;
  right: 37px;
  top: 37px;
  font-size: 56px;
  cursor: pointer;
}
.qr-img {
  width: 245px;
  height: 245px;
  margin: auto;
  padding: 5px;
  background: #fff;
}
.sale {
  font-size: 36px;
}
.title {
  font-size: 16px;
  border-bottom: 1px dashed #fff;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.desc {
  font-size: 14px;
  padding: 10px 0;
}
.el-button {
  width: 100%;
  height: 44px;
  margin-top: 60px;
  border-radius: 50px;
  background-color: transparent;
}
</style>
