import { getRequest, postRequest, putRequest, deleteRequest } from '@/api/request'

// 登录
export const postLogin = (params) => {
  return postRequest('shop_manager/login', params);
};

// 退出登录
export const postUserLoginout = (params) => {
  return postRequest('park_pc/user/loginout', params);
};
