import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/base.scss';


import VueWechatTitle from 'vue-wechat-title';

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
//缓存设置、获取、删除
import { setStore, getStore, removeStore, clearStore } from '@/utils/storage'
import { setCookie, getCookie, removeCookie } from '@/utils/cookie'



import './permission'
const app = createApp(App)
app.config.globalProperties.setStore = setStore
app.config.globalProperties.getStore = getStore
app.config.globalProperties.removeStore = removeStore
app.config.globalProperties.clearStore = clearStore
app.config.globalProperties.setCookie = setCookie
app.config.globalProperties.getCookie = getCookie
app.config.globalProperties.removeCookie = removeCookie
app.config.globalProperties.maxH = window.outerHeight - 180

app.use(ElementPlus, { locale })
app.use(VueWechatTitle)
app.use(store)
app.use(router)
app.mount('#app')
