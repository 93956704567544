<template>
  <div class="home">
    <div class="head flex flex-jsb">
      <div class="head-title"><img src="@/static/images/home-icon.png"> {{ user.shop_name }}</div>
      <div class="head-r flex flex-ac">{{ user.name }} <el-button type="primary" size="mini"
          @click="handleLoginout">退出</el-button>
      </div>
    </div>
    <div class="coupons" v-loading="loading">
      <template v-if="list.list && list.list.length > 0">
        <ul class="flex flex-w">
          <li v-for="(item, index) in list.list" :key="index" :class="['list-' + type.indexOf(item.type)]">
            <div class="coupons-head flex flex-jsb">
              <div class="coupons-value">{{ item.sale }}
                <div>{{ item.type }}</div>
              </div>
              <el-tag effect="plain">可用{{ item.surplus }}张</el-tag>
            </div>
            <div class="coupons-content">
              <div class="coupons-name">{{ item.title }}</div>
              <div class="coupons-time">有效期至：{{ item.end_time }}</div>
              <el-button type="info" @click="handleQr(item)"><img src="@/static/images/qr.png" />生成优惠码</el-button>
            </div>

          </li>
        </ul>
        <el-pagination :hide-on-single-page="false" layout="prev, pager, next" :page-size="form.size" :total="total"
          @current-change="currentChange">
        </el-pagination>
      </template>
      <template v-else>
        <div class="tips">
          <img src="@/static/images/no-coupons.png" />
          <div>没有优惠券了哦</div>
        </div>
      </template>

    </div>
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" :title="title" width="510px" center>
      <div class="dialog-box flex flex-ac flex-jc">
        二维码有效时间<el-input type="number" v-model="curInfo.time" placeholder="请输入二维码有效时间"
          style="width:237px;margin:0 5px"></el-input>
        分钟
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" round @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" round @click="submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as API_index from "@/api/index";
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      loading: false,
      user: {},
      list: [],
      total: 0,
      form: {
        type: 1,
        size: 10,
      },
      type: ['现金券', '折扣券', '免单券', '小时券'],
      curInfo: {
        id: '',
        title: '',
        sale: '',
        time: '',
      },
      dialogVisible: false,
    }
  },
  created() {
    if (this.getStore('token')) {
      this.user = JSON.parse(this.getStore('user'))
    }
  },
  mounted() {
    this.getList()
  },

  methods: {

    getList() {
      API_index.getCouponList(this.form).then(res => {
        this.list.list = res.data
        this.list.total = res.total
      })

    },
    currentChange(e) {
      this.form.size = e
      this.getList()
    },
    handleQr(item) {
      this.curInfo = item
      this.dialogVisible = true

    },
    submit() {
      if (!this.curInfo.time) {
        this.$message({
          type: 'error',
          message: '请输入优惠券有效期！',
        })
        return false
      }
      if (this.curInfo.time > 60) {
        this.$message({
          type: 'error',
          message: '输入时间不能超过60分钟！',
        })
        return false
      }
      this.$router.push('/qr?id=' + this.curInfo.id + '&time=' + this.curInfo.time + '&title=' + this.curInfo.title + '&sale=' + this.curInfo.sale)
    },
    handleLoginout() {
      this.$confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('loginOut').then(() => { })
      }).catch(() => { })
    },

  }
}
</script>
<style lang="scss" scoped>
.home {
  min-height: 100vh;
  background-color: #fff;

  .head {
    height: 35px;
    line-height: 35px;
    padding: 0 30px;
    background-color: #f7f9fc;
    font-size: 14px;

    img {
      width: 21px;
      height: 16px;
    }

    button {
      margin-left: 10px;
    }
  }
}

.coupons {
  width: 95%;
  max-width: 930px;
  margin: auto;

  li {
    width: calc(100% / 3 - 60px);
    box-shadow: 0px 5px 13px 0px #bec5d4;
    margin: 30px;
    border-radius: 8px;
    overflow: hidden;
  }

  .coupons-head {
    height: 76px;
    padding: 12px 20px;
    color: #fff;
    background-color: #fd7171;
    box-sizing: border-box;

    .el-tag {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
    }

    .coupons-value {
      font-size: 24px;

      div {
        font-size: 12px;
      }
    }
  }

  .list-0 {

    .coupons-head,
    .el-button {
      background-color: #fd7171;
    }
  }

  .list-1 {

    .coupons-head,
    .el-button {
      background-color: #44d7b6;
    }
  }

  .list-2 {

    .coupons-head,
    .el-button {
      background-color: #60aefb;
    }
  }

  .list-3 {

    .coupons-head,
    .el-button {
      background-color: #f7c665;
    }
  }

  .coupons-content {
    padding: 20px;

    .coupons-time {
      font-size: 12px;
      margin-top: 20px;
    }

    .el-button {
      width: 100%;
      margin-top: 75px;
      font-size: 16px;
      color: #fff;
      border-color: transparent;

      :deep(span) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }
}

:deep(.el-dialog) {
  border-radius: 10px;

  .el-dialog__header {
    display: none;
  }
}

.dialog-box {
  padding: 50px 0;
}

.tips {
  text-align: center;
  color: #9a9a9a;
  font-size: 16px;
  margin-top: 30vh;

  img {
    width: 86px;
    height: 65px;
    margin: 20px auto;
  }
}

.dialog-footer {
  .el-button {
    width: 160px;
    margin-bottom: 50px;
  }
}
</style>
