import { createStore } from 'vuex'
import { clearStore } from '@/utils/storage'
import router from '../router'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
    loginOut() {
      clearStore()
      router.replace('/login')
    }
  },
  modules: {

  }
})
